/* Estilo global para el lienzo de la firma */

.signature {
    position: relative;
    width: 100%; /* Hace que el lienzo ocupe todo el ancho disponible */
    height: auto; /* Altura dinámica basada en el contenedor */
    max-height: 200px; /* Máximo de altura para evitar que se extienda demasiado */
    border: 1px solid black; /* Borde negro alrededor del lienzo */
    border-radius: 8px; /* Bordes redondeados */
}
.canvas {
    border-width: 2px;
    background-color: #fff; /* Fondo blanco para el lienzo */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra ligera alrededor del lienzo */
}

/* Estilos para el contenedor de los botones */
.button-canvas-container {
  text-align: center;
  margin-top: 20px;
}

/* Estilo de los botones */
button-canvas {
  padding: 10px 20px;
  font-size: 14px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid transparent;
  transition: all 0.3s ease;
}

button-canvas:hover {
  background-color: #f0f0f0;
  border-color: #ddd;
}

button-canvas:focus {
  outline: none;
}

/* Estilos para el botón de limpiar */
.btn-canvas-outline-secondary {
  background-color: #f8f9fa;
  color: #6c757d;
}

.btn-canvas-outline-secondary:hover {
  background-color: #e2e6ea;
  color: #495057;
}

/* Estilos para el botón de confirmar */
.btn-canvas-outline-primary {
  background-color: #007bff;
  color: #fff;
}

.btn-canvas-outline-primary:hover {
  background-color: #0056b3;
  color: #fff;
}

/* Estilo para la etiqueta de texto */
.signature-canvas-label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  display: block;
  margin-bottom: 10px;
}

/* Asegurarse de que el contenedor sea flexible en tamaño */
.signature-canvas-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}
